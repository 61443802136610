<template>
  <main ref="homeContainer">
    <TheHeader/>
    <section>
      <TheSideNav/>
      <router-view></router-view>
    </section>
  </main>
</template>

<script>
import TheHeader from '../../components/TheHeader';
import TheSideNav from '../../components/TheSideNav';
import moment from 'moment'
import {eventBus} from '@/main.js';
export default {
  components:{
    TheHeader,
    TheSideNav
  },
  async created(){
    eventBus.$emit('loader',true);

    const params = {
      week: moment().isoWeek()
    };


    try {
      await this.$store.dispatch('callGameList');
      await this.$store.dispatch('callLeaderboardList',{params});
      await this.$store.dispatch('callStaticLinks')
      eventBus.$emit('loader',false);
    } catch (error) {
      eventBus.$emit('loader',false);
    }
  }
}
</script>

<style lang="scss" scoped>
section{
  height: calc(100vh - 60px);
  overflow: hidden;
  display: flex;
  position: relative;

  @media screen and (max-width: 768px){
    min-height: calc(100vh - 58px);
    height: unset;
    overflow: unset;
  }
}


main{
  max-height: 100vh;
  overflow-y: hidden;

  @media screen and (max-width: 768px){
    max-height: unset;
  }
}
</style>