<template>
  <mounting-portal mountTo="body" append>
    <aside :class="{visible : openSideBar}" @click="toggle">
      <div class="sidebar">
        <div class="nav-section"> 
          <ul>
            <NavItem icon="dashboard" name="Game Dashboard" to="/dashboard" />
            <NavItem icon="leaderboard" name="Leaderboard" to="/leaderboard" />
            <NavItem icon="contract" name="Contract Specification" to="/contract" />
            <NavItem icon="invite" name="Invite Friends" to="/invite" />
            <NavItem icon="walkthrough" name="Game Walkthrough" to="/game-walkthrough" />
            <NavItem icon="rules" name="Rules of Game" to="/rules" />
            <NavItem icon="contact" name="Contact Us" to="/contact" />
            <NavItem icon="user" name="Profile" :to="profileRoute" v-if="isMobile" />
            <NavItem icon="signout" name="Sign Out" to="/signout" />
          </ul>
          <div class="extenal-links-section">
            <ExternalLink icon="account" link="https://www.kenangafutures.com.my/open-an-account/">Open An Account</ExternalLink>
            <ExternalLink icon="education" link="https://www.kenangafutures.com.my/e-learning/">Educational Materials</ExternalLink>
            <ExternalLink icon="coaching" link="https://www.kenangafutures.com.my/events/one-on-one-coaching-by-kenanga-futures/">1:1 Coaching Session</ExternalLink>
            <ExternalLink icon="account" link="https://www.kenangafutures.com.my/events-calendar/">Event Calendar</ExternalLink>
            <ExternalLink link="https://www.kenangafutures.com.my/events/live-webinar-experience-futures-today-cme-group-us-indices/">Upcoming Event</ExternalLink>
            <img src="../assets/images/trading_webinar.png" alt="trading" style="margin-bottom:10px">
          </div>
        </div>
      </div>
    </aside>
  </mounting-portal>
</template>

<script>
import NavItem from './NavItem';
import {MountingPortal} from 'portal-vue'
import {eventBus} from '../main';
import ExternalLink from './ExternalLink';
export default {
  components:{
    NavItem,
    MountingPortal,
    ExternalLink
  },
  data(){
    return{
      openSideBar : true,
      profileRoute: '',
      isMobile: false,
    }
  },
  created(){
    eventBus.$on('toggleSideBar', () => {
      this.openSideBar = true;
    });
  },
  mounted(){
    var windowWidth = window.innerWidth;
    windowWidth > 768 ? this.openSideBar =  true : this.openSideBar = false;
    windowWidth > 768 ? this.isMobile =  false : this.isMobile = true;

    const vm = this;

    window.addEventListener("resize", function () {
      var windowWidth = window.innerWidth;
      windowWidth > 768 ?  vm.openSideBar = true : vm.openSideBar = false;
      windowWidth > 768 ?  vm.isMobile = false : vm.isMobile = true;
    });
    this.profileRoute = this.userRole === 'admin' ? '/admin/profile/' : '/profile'; 
  },
  methods:{
    toggle(){
      const windowInnerWidth = window.innerWidth;
      if(windowInnerWidth < 768){
        this.openSideBar = !this.openSideBar;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarGray686868) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarGray686868);
  border-radius: 20px;
}


aside{
  position: fixed;
  top: 63px;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 230px;
  transform: translateX(-100%);
  transition: all 0.3s;

   @media screen and (max-width:768px){
    width: 100%;
    top: 58px;
  }

  &.visible {
    transform: translateX(0);
    -webkit-transform: translate3d(0, 0, 0);
  }

  .sidebar{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    max-width: 200px;

    @media screen and (max-width:768px){
      width: 200px;
    }
  }

  .nav-section{
    background: var(--gray414241);
    height: calc(100vh - 60px);
    overflow: auto;

    @media screen and (max-width:768px){
      height: calc(100vh - 58px);
    }

    .extenal-links-section{
      img{
        margin-left: 10px;
        width: 180px;
        height: auto;
       
      }
    }
  }

}
</style>    